import { render, staticRenderFns } from "./Applist.vue?vue&type=template&id=4e312e94&"
import script from "./Applist.vue?vue&type=script&lang=js&"
export * from "./Applist.vue?vue&type=script&lang=js&"
import style0 from "./Applist.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpinnerBall from 'quasar/src/components/spinner/QSpinnerBall.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QCard,QSkeleton,QSlideTransition,QSeparator,QSpace,QDialog,QInput,QSpinnerBall});
