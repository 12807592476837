















import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';

export default Vue.extend({
	name: 'Home',
	methods: {
		async logout() {
			await firebase.auth().signOut();
			this.$router.push('/');
		}
	}
});
