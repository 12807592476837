<template>
	<div class="applist column items-center">
		<div class="row top-buttons justify-around">
			<q-btn v-if="$route.query.branch" label="Back" icon="arrow_back" :to="'/apps/' + $route.params.platform" />
			<q-btn label="Refresh" icon="refresh" @click="refreshApps()" />
		</div>
		<q-card v-if="apps.length == 0 && working">
			<div class="column">
				<q-skeleton type="text" />
				<q-skeleton type="text" />
				<q-skeleton type="text" />
			</div>
		</q-card>
		<q-card v-for="app in apps" :key="app.id">
			<div class="title row no-wrap justify-between">
				<div class="column justify-start">
					<h1>{{ app.title || app.branch_name }}</h1>
					<p>{{ app.date.toLocaleDateString() }} {{ app.date.toLocaleTimeString() }}<span v-if="app.build_number"> | build #{{ app.build_number }}</span></p>
					<p>Uploaded by {{ app.uploaded_by }}</p>
				</div>
				<q-btn color="grey" round flat dense :icon="app.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" @click="app.expanded = !app.expanded" />
			</div>
			<q-slide-transition><div v-show="app.expanded">
				<q-separator />
				<p v-if="app.title">Branch name: {{ app.branch_name }}</p>
				<p>Points to {{ app.server_target }}</p>
				<p class=notes>{{ app.notes }}</p>
				<div class="row justify-between btn-row">
					<q-btn icon="edit" flat round @click="edit(app)" v-if="!$route.query.branch"/>
					<q-space />
					<q-btn :to="'/apps/' + $route.params.platform + '?branch=' + app.branch_name" icon="history" label="history" color="secondary" v-if="!$route.query.branch" />
					<q-btn type="a" v-bind:href="app.link" icon="download" label="Download" color="positive" class="space-left" />
				</div>
			</div></q-slide-transition>
		</q-card>

		<q-dialog class="edit" v-model="editOpen">
			<q-card>
				<h1 class='dialog-title'>{{ editingBranch }}</h1>
				<q-input v-model="editTitle" label="Title"/>
				<q-input v-model="editNotes" type="textarea" label="Notes" />
				<br />
				<div class="row btn-row">
					<q-btn label="Delete" icon="delete" color="negative" @click="confirmDelete()" />
					<q-space />
					<q-btn label="Save" icon="save" color="positive" @click="save()" />
				</div>
			</q-card>
		</q-dialog>

		<q-dialog class="confirmDelete" v-model="confirmDeleteOpen" :no-esc-dismiss="true" :no-backdrop-dismiss="true" :no-route-dismiss="true">
			<q-card>
				<h1 class='dialog-title'>Deleting {{ editingBranch }}</h1>
				<p>You are about to delete all builds from the {{ editingBranch }} branch.</p>
				<p>You should probably only be doing this if the branch has been released or discarded.</p>
				<p>Are you sure you want to do this?</p>
				<div class="row btn-row">
					<q-btn label="Delete All Builds" icon="delete_sweep" color="negative" @click="runDelete()" :disable="working" />
					<q-spinner-ball class="delete-spinner" size="30px" v-if="working" color="negative"/>
					<q-space />
					<q-btn label="Cancel" color="positive" @click="confirmDeleteOpen = false" :disable="working" />
				</div>
			</q-card>
		</q-dialog>
	</div>
</template>

<style lang=scss>
.applist {
	max-width: 100vw;
}
.top-buttons {
	width: 100%;
	max-width: 500px;
	margin: 16px 0px;
}
.q-card {
	margin: 16px 0px;
	padding: 16px;
	width: 95%;
	max-width: 500px;
	p {
		margin: 8px;
	}
	.q-separator {
		margin: 8px 0px;
	}
	.notes {
		white-space: pre-wrap;
	}
}
.space-left {
	margin-left: 8px;
}
.title {
	h1 {
		padding-right: 16px;
		margin-bottom: 8px;
	}
	p {
		margin: 0px 8px;
	}
	.q-btn {
		width: 50px;
		height: 50px;
	}
}
.dialog-title {
	text-align: center;
}
.delete-spinner {
	margin-left: 16px;
}
@media only screen and (max-width: 390px) {
	.space-left {
		margin-left: 2px;
	}
	.btn-row {
		margin: 0px -8px -6px -12px;
	}
}
</style>


<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

export default {
	name: 'AppList',

	data () {
		return {
			baseUrl: "https://storage.googleapis.com/internal-test-app-distribution.appspot.com/",
			apps: [],
			branches: {},
			editOpen: false,
			editingBranch: "",
			editingApp: null,
			editTitle: "",
			editNotes: "",
			confirmDeleteOpen: false,
			working: false,
		}
	},
	mounted: async function() {
		this.refreshApps();
	},
	methods: {
		async refreshApps() {
			this.working = true;
			this.branches = {};
			const branchData = await firebase.firestore()
				.collection(this.$route.params.platform + "-branches")
				.get()
			branchData.forEach((doc) => {
				const data = doc.data();
				this.branches[decodeURIComponent(doc.id)] = data;
			});

			firebase.firestore()
			.collection(this.$route.params.platform)
			.orderBy("date", "desc")
			.get()
			.then((snapshot) => {
				const appData = [];
				snapshot.forEach((doc) => {
					const data = doc.data();
					data['id'] = doc.id;
					data['date'] = data.date.toDate();
					data['expanded'] = false;
					if (this.branches[data.branch_name]) {
						data['title'] = this.branches[data.branch_name].title || null;
						data['notes'] = this.branches[data.branch_name].notes || null;
					}
					if (this.$route.params.platform === 'ios') {
						data['link'] = `itms-services://?action=download-manifest&url=${this.baseUrl}${data.plist_path}`;
					} else {
						data['link'] = this.baseUrl + data.app_path;
					}
					appData.push(data);
					if (this.$route.query.branch) {
						this.apps = appData.filter(x => x.branch_name == this.$route.query.branch);
					} else {
						this.apps = appData.reduce((arr, v) => {
							if (!arr.find(x => x.branch_name === v.branch_name)) {
								arr.push(v);
							}
							return arr;
						}, []);
					}
				});
			});
			this.working = false;
		},
		edit(app) {
			this.editingBranch = app.branch_name
			this.editingApp = app
			const branch = this.branches[this.editingBranch] || {};
			this.editTitle = branch.title || app.branch_name;
			this.editNotes = branch.notes || "";
			this.editOpen = true;
		},
		async save() {
			await firebase.firestore()
				.collection(this.$route.params.platform + "-branches")
				.doc(encodeURIComponent(this.editingBranch))
				.set({
					title: this.editTitle,
					notes: this.editNotes,
				}, { merge: true });
			this.editingBranch = null;
			location.reload();
		},
		confirmDelete() {
			this.confirmDeleteOpen = true;
		},
		async runDelete() {
			this.working = true;
			const firestore = firebase.firestore();
			const storage = firebase.storage();
			const appsToDelete = await firestore
				.collection(this.$route.params.platform)
				.where('branch_name', '==', this.editingBranch)
				.get()
			for (const app of appsToDelete.docs) {
				const data = app.data();
				const filesToDelete = [data.app_path];
				if (data.plist_path) {
					filesToDelete.push(data.plist_path);
				}
				filesToDelete.forEach(async (filePath) => {
					await storage.ref(filePath).delete();
				});
				await firestore.collection(this.$route.params.platform)
					.doc(app.id)
					.delete();
			}
			await firestore.collection(this.$route.params.platform + "-branches")
				.doc(encodeURIComponent(this.editingBranch))
				.delete();
			location.reload();
		},
	},
}
</script>
